<script>
    import ProfileSidebar from "./ProfileSidebar";
    import {mapState} from "vuex";
    /**
     * User Company Details Form component
     */
    export default {
        page: {
            title: 'Λογαριασμός'
        },
        components: {ProfileSidebar },
      computed:{
        ...mapState({
          user: state => state.auth.currentUser
        })
      },
      data() {
        return {
            userCompany: {
                companyName: '',
                title: '',
                occupation: '',
                address: '',
                VAT: '',
                DOY: '',
                telephone: null,
                mobile:null,
            },

            updateError: null,
            isBtnDisabled: true,
            previousFormState: '',
            currentFormState: '',
        }
      },
      mounted: function(){

          this.userCompany = JSON.parse(JSON.stringify(this.user.userCompany));

          this.previousFormState = this.currentFormState = this.userCompany.companyName + this.userCompany.title + this.userCompany.occupation
              + this.userCompany.address + this.userCompany.VAT + this.userCompany.DOY + this.userCompany.telephone + this.userCompany.mobile;
          this.currentFormState = this.previousFormState
      },

      beforeDestroy() {
          if(!this.isBtnDisabled){
              this.updateCompanyDetails();
          }
      },

      methods:{
          updateCompanyDetails: function(){
              this.isBtnDisabled =true;
              this.previousFormState = this.currentFormState;

              return this.$store.dispatch('auth/updateUserCompany', this.userCompany).then(result =>{
                  this.$notify({group: 'athlisis-notifications', type:'success',title: this.$t('success.title'), text: 'Τα στοιχεία ενημερώθηκαν επιτυχώς!'});
                  this.checkForDifferences()
              }).catch( error => {
                  this.updateError = error;
                  this.checkForDifferences()
                  this.$notify({group: 'athlisis-notifications', type:'error', title: this.$t('errors.title'), text: 'Η αποθήκευση απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
              })
          },


          checkForDifferences: function(){
              this.currentFormState = this.userCompany.companyName + this.userCompany.title + this.userCompany.occupation
                  + this.userCompany.address + this.userCompany.VAT + this.userCompany.DOY + this.userCompany.telephone + this.userCompany.mobile;
              this.isBtnDisabled = this.currentFormState === this.previousFormState;
          },

      }
    }
</script>

<template>
<div id="company-details-form-layout">
  <div class="row">
      <ProfileSidebar></ProfileSidebar>

      <b-card class="col-lg-9 col-xl-9">
        <b-card-title>Στοιχεία Εταιρείας</b-card-title>
        <b-card-sub-title>Εισάγετε τα στοιχεία της τιμολόγησής σας</b-card-sub-title>
          <div class="mt-4">

            <b-form @submit.prevent="updateCompanyDetails">

              <div class="row">
                  <div class="col-md-6">
                      <b-form-group id="input-1" label="Επωνυμία εταιρείας" label-for="companyName" >
                          <b-form-input id="companyName" v-model="userCompany.companyName" type="text" placeholder="Εισάγετε την επωνυμία της εταιρείας σας" @input="checkForDifferences"></b-form-input>
                      </b-form-group>
                  </div>
                  <div class="col-md-6">
                      <b-form-group id="input-2" label="Επάγγελμα" label-for="occupation">
                          <b-form-input id="occupation" v-model="userCompany.occupation" type="text" placeholder="Εισάγετε το επάγγελμά σας (τομέας δραστηριότητας της εταιρείας)" @input="checkForDifferences"></b-form-input>
                      </b-form-group>
                  </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                    <b-form-group id="input-5" label="Σταθερό τηλέφωνο" label-for="telephone" >
                        <b-form-input id="telephone" v-model="userCompany.telephone" type="text" placeholder="Εισάγετε το σταθερό σας τηλέφωνο" @input="checkForDifferences"></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-md-6">
                    <b-form-group id="input-6" label="Κινητό Τηλέφωνο" label-for="mobile">
                        <b-form-input id="mobile" v-model="userCompany.mobile" type="text" placeholder="Εισάγετε το κινητό σας" @input="checkForDifferences"></b-form-input>
                    </b-form-group>
                </div>
              </div>

              <div class="row">
                  <div class="col-md-4">
                      <b-form-group id="input-3" label="Διεύθυνση" label-for="address" >
                          <b-form-input id="address" v-model="userCompany.address" type="text" placeholder="Εισάγετε την διεύθυνση της εταιρείας σας" @input="checkForDifferences"></b-form-input>
                      </b-form-group>
                  </div>
                  <div class="col-md-4">
                      <b-form-group id="input-4" label="Α.Φ.Μ" label-for="VAT">
                          <b-form-input id="VAT" v-model="userCompany.VAT" type="text" placeholder="Εισάγετε τον Α.Φ.Μ. σας" @input="checkForDifferences"></b-form-input>
                      </b-form-group>
                  </div>
                  <div class="col-md-4">
                      <b-form-group id="input-7" label="Δ.Ο.Υ." label-for="DOY" >
                          <b-form-input id="DOY" v-model="userCompany.DOY" type="text" placeholder="Εισάγετε τη Δ.Ο.Υ. σας" @input="checkForDifferences"></b-form-input>
                      </b-form-group>
                  </div>

              </div>



              <div class="mt-2 text-right">
                <save-button type="submit" :disabled="isBtnDisabled"></save-button>
              </div>


            <b-alert v-model="updateError" variant="danger" dismissible>
                {{updateError}}
            </b-alert>

            </b-form>

          </div>
        </b-card>
    </div>
</div>
</template>
